import { DashboardService } from '@/api';
import { ProjectReport } from '@/resource/model';
import { messageError } from '@/utils';
import { Component, Vue } from 'vue-property-decorator';
import { ReportSummaryOption } from '../report-summary/report-summary';
import ReportSummary from '../report-summary/report-summary.vue';
@Component({
  components: { ReportSummary }
})
export default class Project extends Vue {
  public reportOptions: Array<ReportSummaryOption<ProjectReport>> = [
    {
      name: 'projectStatus.new',
      prop: 'newProject'
    },
    {
      name: 'projectStatus.underway',
      prop: 'inProcessProject'
    }
  ];

  public data: ProjectReport | {} = {};

  public created(): void {
    DashboardService.getProjectReport()
      .then(res => {
        this.data = res;
      })
      .catch(error => {
        messageError(error);
      });
  }
}
