import { DashboardService } from '@/api';
import { PrepressTodoReport } from '@/resource/model';
import { messageError } from '@/utils';
import { Component, Vue } from 'vue-property-decorator';
import { ReportSummaryOption } from '../report-summary/report-summary';
import ReportSummary from '../report-summary/report-summary.vue';
@Component({
  components: { ReportSummary }
})
export default class PrepressTodo extends Vue {
  public reportOptions: Array<ReportSummaryOption<PrepressTodoReport>> = [
    {
      name: 'prepressReport.productionOrder',
      prop: 'inProductionItemCount',
      tip: 'prepressReport.productionOrderTip'
    },
    {
      name: 'prepressReport.waitingForCompleteOrder',
      prop: 'toBeCompleteItemCount',
      tip: 'prepressReport.waitingForCompleteOrderTip'
    }
  ];

  public data: PrepressTodoReport | {} = {};

  public created(): void {
    DashboardService.getPrepressTodoReport()
      .then(res => {
        this.data = res;
      })
      .catch(error => {
        messageError(error);
      });
  }
}
