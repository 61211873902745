import { Component, Vue } from 'vue-property-decorator';
import ReportSummary from '../report-summary/report-summary.vue';
import { OsShortcutsOptions } from '@/components/os-time-picker-linkage/os-time-picker-linkage';
import { SaleReport } from '@/resource/model';
import moment from 'moment';
import { ReportSummaryOption } from '../report-summary/report-summary';
import { DashboardService } from '@/api';
import { messageError } from '@/utils';
@Component({
  components: { ReportSummary }
})
export default class Sale extends Vue {
  public reportQuery = {
    rangeTime: [],
    customerId: undefined
  };

  public shortcutsOptions: Array<OsShortcutsOptions> = [
    {
      text: 'dashboard.thisMonth',
      value: 'month',
      rangeTime(): [Date, Date] {
        const start = new Date();
        start.setDate(1);
        return [
          moment()
            .startOf('month')
            .toDate(),
          moment()
            .endOf('month')
            .toDate()
        ];
      }
    },
    {
      text: 'dashboard.thisSeason',
      value: 'season',
      rangeTime(): [Date, Date] {
        return [
          moment()
            .startOf('quarter')
            .toDate(),
          moment()
            .endOf('quarter')
            .toDate()
        ];
      }
    },
    {
      text: 'dashboard.thisYear',
      value: 'year',
      rangeTime(): [Date, Date] {
        return [
          moment()
            .startOf('year')
            .toDate(),
          moment()
            .endOf('year')
            .toDate()
        ];
      }
    }
  ];

  public reportOptions: Array<ReportSummaryOption<SaleReport>> = [
    {
      name: 'saleReport.orderArea',
      prop: 'orderTotalArea',
      tip: 'saleReport.orderAreaTip',
      unit: '㎡',
      toFixd: 2
    },
    {
      name: 'saleReport.producedArea',
      prop: 'producedArea',
      tip: 'saleReport.producedAreaTip',
      unit: '㎡',
      toFixd: 2
    },
    {
      name: 'saleReport.productionArea',
      prop: 'inProductionArea',
      tip: 'saleReport.productionAreaTip',
      unit: '㎡',
      toFixd: 2
    },
    {
      name: 'saleReport.notProduceYetArea',
      prop: 'unProductionArea',
      tip: 'saleReport.notProduceYetAreaTip',
      unit: '㎡',
      toFixd: 2
    },
    {
      name: 'saleReport.consumeArea',
      prop: 'attritionTotalArea',
      tip: 'saleReport.consumeAreaTip',
      unit: '㎡',
      toFixd: 2
    }
  ];

  public data: SaleReport | {} = {};

  public loadData(): void {
    const [startTime, endTime] = this.reportQuery.rangeTime;
    const query = { customerId: this.reportQuery.customerId, startTime, endTime };
    DashboardService.getSaleReport(query)
      .then(res => {
        this.data = res;
      })
      .catch(error => {
        messageError(error);
      });
  }
}
