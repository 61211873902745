import { render, staticRenderFns } from "./change-color.vue?vue&type=template&id=4a63f127&scoped=true&"
import script from "./change-color.ts?vue&type=script&lang=ts&"
export * from "./change-color.ts?vue&type=script&lang=ts&"
import style0 from "./change-color.scoped.scss?vue&type=style&index=0&id=4a63f127&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a63f127",
  null
  
)

export default component.exports