import { render, staticRenderFns } from "./complete-manuscript.vue?vue&type=template&id=07c43249&scoped=true&"
import script from "./complete-manuscript.ts?vue&type=script&lang=ts&"
export * from "./complete-manuscript.ts?vue&type=script&lang=ts&"
import style0 from "./complete-manuscript.scoped.scss?vue&type=style&index=0&id=07c43249&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07c43249",
  null
  
)

export default component.exports