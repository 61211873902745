import { DashboardService } from '@/api';
import { OsShortcutsOptions } from '@/components/os-time-picker-linkage/os-time-picker-linkage';
import { PrepressReport } from '@/resource/model';
import { messageError } from '@/utils';
import moment from 'moment';
import { Component, Vue } from 'vue-property-decorator';
import { ReportSummaryOption } from '../report-summary/report-summary';
import ReportSummary from '../report-summary/report-summary.vue';

@Component({
  components: { ReportSummary }
})
export default class CompleteManuscript extends Vue {
  public reportQuery = {
    rangeTime: [],
    code: ''
  };

  public shortcutsOptions: Array<OsShortcutsOptions> = [
    {
      text: 'dashboard.next7Days',
      value: 'next7Days',
      rangeTime(): [Date, Date] {
        const start = new Date();
        start.setDate(1);
        return [
          moment()
            .startOf('d')
            .toDate(),
          moment()
            .add(6, 'd')
            .endOf('d')
            .toDate()
        ];
      }
    },
    {
      text: 'dashboard.next14Days',
      value: 'next14Days',
      rangeTime(): [Date, Date] {
        return [
          moment()
            .startOf('d')
            .toDate(),
          moment()
            .add(13, 'd')
            .endOf('d')
            .toDate()
        ];
      }
    },
    {
      text: 'dashboard.next30Days',
      value: 'next30Days',
      rangeTime(): [Date, Date] {
        return [
          moment()
            .startOf('d')
            .toDate(),
          moment()
            .add(30, 'd')
            .endOf('d')
            .toDate()
        ];
      }
    }
  ];

  public reportOptions: Array<ReportSummaryOption<PrepressReport>> = [
    {
      name: 'prepressReport.typesettingWaitingForOrder',
      prop: 'toBeReceived',
      queryPlanName: '排版待接单',
      path: '/complete-manuscript-task'
    },
    {
      name: 'prepressReport.finishedProductWaitingForOrder',
      prop: 'toBeFinishProductReceived',
      queryPlanName: '成品待接单',
      path: '/complete-manuscript-task'
    },
    {
      name: 'prepressReport.typesetting',
      prop: 'inProductionItem',
      queryPlanName: '排版制作中',
      path: '/complete-manuscript-task'
    },
    {
      name: 'prepressReport.finishedProduct',
      prop: 'inFinishProductProductionItem',
      queryPlanName: '成品制作中',
      path: '/complete-manuscript-task'
    },
    {
      name: 'prepressReport.waitingForConfirm',
      prop: 'toBeConfirm',
      queryPlanName: '待确稿',
      path: '/complete-manuscript-task'
    }
  ];

  public data: PrepressReport | {} = {};

  public showErrorTip = false;

  public loadData(): void {
    if (this.reportQuery.code.length > 0 && this.reportQuery.code.length < 14) {
      this.showErrorTip = true;
      return;
    } else {
      this.showErrorTip = false;
    }
    const [startTime, endTime] = this.reportQuery.rangeTime;
    const query = { code: this.reportQuery.code, startTime, endTime };
    DashboardService.getCompleteManuscriptReport(query)
      .then(res => {
        this.data = res;
      })
      .catch(error => {
        messageError(error);
      });
  }
}
