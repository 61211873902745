import { DashboardService } from '@/api';
import { AETodoReport } from '@/resource/model';
import { messageError } from '@/utils';
import { Component, Vue } from 'vue-property-decorator';
import { ReportSummaryOption } from '../report-summary/report-summary';
import ReportSummary from '../report-summary/report-summary.vue';
@Component({
  components: { ReportSummary },
  name: 'AE-todo'
})
export default class AETodo extends Vue {
  public reportOptions: Array<ReportSummaryOption<AETodoReport>> = [
    {
      name: 'AETodo.overdueProject',
      prop: 'overdueProject',
      queryPlanName: '已逾期',
      path: '/project'
    },
    {
      name: 'AETodo.toBeCompletedProject',
      prop: 'toBeCompletedProject',
      queryPlanName: '待完成',
      path: '/project'
    },
    {
      name: 'AETodo.notDistributeItem',
      prop: 'notDistributeItem',
      queryPlanName: '待派发生产',
      path: '/sale-details'
    },
    {
      name: 'AETodo.uncommittedPrepressItem',
      prop: 'uncommittedPrepressItem',
      queryPlanName: '待提交印前',
      path: '/sale-details'
    },
    {
      name: 'AETodo.toBeConfirm',
      prop: 'toBeConfirm',
      queryPlanName: '待确稿',
      path: '/sale-details'
    },
    {
      name: 'AETodo.toBeDeliveryItem',
      prop: 'toBeDeliveryItem',
      queryPlanName: '待发货',
      path: '/sale-details'
    },
    {
      name: 'AETodo.toBeInstallItem',
      prop: 'toBeInstallItem',
      queryPlanName: '待安装',
      path: '/sale-details'
    },
    {
      name: 'AETodo.unsettledItem',
      prop: 'unsettledItem',
      queryPlanName: '待结算',
      path: '/sale-details'
    }
  ];

  public data: AETodoReport | {} = {};

  public created(): void {
    DashboardService.getAETodoReport()
      .then(res => {
        this.data = res;
      })
      .catch(error => {
        messageError(error);
      });
  }
}
