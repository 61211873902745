import { DashboardService } from '@/api';
import { FinanceTodoReport } from '@/resource/model';
import { messageError } from '@/utils';
import { Component, Vue } from 'vue-property-decorator';
import { ReportSummaryOption } from '../report-summary/report-summary';
import ReportSummary from '../report-summary/report-summary.vue';
@Component({
  components: { ReportSummary }
})
export default class FinanceTodo extends Vue {
  public reportOptions: Array<ReportSummaryOption<FinanceTodoReport>> = [
    {
      name: 'financeTodo.toBeApprovalBillCount',
      prop: 'toBeApprovalBillCount',
      tip: 'financeTodo.waitingApprovalBillTip',
      queryPlanName: '待审批',
      path: '/billing'
    },
    {
      name: 'financeTodo.toBeApprovalInvoiceCount',
      prop: 'toBeApprovalInvoiceCount',
      tip: 'financeTodo.waitingApprovalInvoiceTip',
      queryPlanName: '待审批',
      path: '/finance-invoice'
    },
    {
      name: 'financeTodo.waitingInvoice',
      prop: 'toBeInvoiceCount',
      tip: 'financeTodo.waitingInvoiceTip',
      queryPlanName: '待开票',
      path: '/finance-invoice'
    },
    {
      name: 'financeTodo.waitingMyApproval',
      prop: 'toBeMyApprovalCount',
      tip: 'financeTodo.waitingMyApprovalTip',
      queryPlanName: '待审批',
      path: '/todo'
    }
  ];

  public data: FinanceTodoReport | {} = {};

  public created(): void {
    DashboardService.getFinanceTodoReport()
      .then(res => {
        this.data = res;
      })
      .catch(error => {
        messageError(error);
      });
  }
}
