import { Component, Vue } from 'vue-property-decorator';
import Sale from './sale/sale.vue';
import Finance from './finance/finance.vue';
import ChangeColor from './change-color/change-color.vue';
import CompleteManuscript from './complete-manuscript/complete-manuscript.vue';
import PrepressTodo from './prepress-todo/prepress-todo.vue';
import Project from './project/project.vue';
import AETodo from './AE-todo/AE-todo.vue';
import FinanceTodo from './finance-todo/finance-todo.vue';
import AESale from './AE-sale/AE-sale.vue';
import MineChangeColorTodo from './mine-change-color-todo/mine-change-color-todo.vue';
import MineComplateManuscriptTodo from './mine-complate-manuscript-todo/mine-complate-manuscript-todo.vue';
import ExceptionReport from './exception-report/exception-report.vue';
import { PermissionModule } from '@/store/modules/permission';
@Component({
  components: {
    Sale,
    Finance,
    ChangeColor,
    CompleteManuscript,
    PrepressTodo,
    Project,
    AETodo,
    FinanceTodo,
    AESale,
    ExceptionReport,
    MineChangeColorTodo,
    MineComplateManuscriptTodo
  }
})
export default class extends Vue {
  public show(permissionCode: string): boolean {
    const operations: Array<string> = PermissionModule.permissionOperations;
    return operations.includes(permissionCode);
  }
}
