import { DashboardService } from '@/api';
import { MineTask } from '@/resource/model';
import { messageError } from '@/utils';
import { Component, Vue } from 'vue-property-decorator';
import { ReportSummaryOption } from '../report-summary/report-summary';
import ReportSummary from '../report-summary/report-summary.vue';

@Component({
  components: {
    ReportSummary
  }
})
export default class MineComplateManuscriptTodo extends Vue {
  public reportOptions: Array<ReportSummaryOption<MineTask>> = [
    {
      name: 'prepressReport.typesetting',
      prop: 'inProductionItemCount',
      queryPlanName: '制作中',
      path: '/mine-typesetting-task'
    },
    {
      name: 'prepressReport.finishedProduct',
      prop: 'inFinishProductProductionItem',
      queryPlanName: '制作中',
      path: '/mine-finished-product-task'
    },
    {
      name: 'prepressReport.timeoutItem',
      prop: 'timeoutItem',
      queryPlanName: '逾期未交稿',
      path: '/mine-typesetting-task'
    },
    {
      name: 'prepressReport.waitingForConfirm',
      prop: 'toBeConfirmItem',
      queryPlanName: '待确稿',
      path: '/mine-typesetting-task'
    }
  ];

  public data: MineTask | {} = {};

  public created(): void {
    DashboardService.getMineComplateTodoReport()
      .then(res => {
        this.data = res;
      })
      .catch(error => {
        messageError(error);
      });
  }
}
