import { Component, Vue } from 'vue-property-decorator';
import ReportSummary from '../report-summary/report-summary.vue';
import { OsShortcutsOptions } from '@/components/os-time-picker-linkage/os-time-picker-linkage';
import { FinanceReport } from '@/resource/model';
import moment from 'moment';
import { ReportSummaryOption } from '../report-summary/report-summary';
import { DashboardService } from '@/api';
import { messageError } from '@/utils';
@Component({
  components: { ReportSummary }
})
export default class Finance extends Vue {
  public reportQuery = {
    rangeTime: [],
    customerId: undefined
  };

  public shortcutsOptions: Array<OsShortcutsOptions> = [
    {
      text: 'dashboard.thisMonth',
      value: 'month',
      rangeTime(): [Date, Date] {
        const start = new Date();
        start.setDate(1);
        return [
          moment()
            .startOf('month')
            .toDate(),
          moment()
            .endOf('month')
            .toDate()
        ];
      }
    },
    {
      text: 'dashboard.thisSeason',
      value: 'season',
      rangeTime(): [Date, Date] {
        return [
          moment()
            .startOf('quarter')
            .toDate(),
          moment()
            .endOf('quarter')
            .toDate()
        ];
      }
    },
    {
      text: 'dashboard.thisYear',
      value: 'year',
      rangeTime(): [Date, Date] {
        return [
          moment()
            .startOf('year')
            .toDate(),
          moment()
            .endOf('year')
            .toDate()
        ];
      }
    }
  ];

  public reportOptions: Array<ReportSummaryOption<FinanceReport>> = [
    {
      name: 'financeReport.invoiceTotalAmount',
      prop: 'invoiceTotalAmount',
      tip: 'financeReport.invoiceTotalAmountTip',
      prefix: '¥',
      toFixd: 2
    },
    {
      name: 'financeReport.invoicedAmount',
      prop: 'invoicedAmount',
      tip: 'financeReport.invoicedAmountTip',
      prefix: '¥',
      toFixd: 2
    },
    {
      name: 'financeReport.unInvoiceAmount',
      prop: 'unInvoiceAmount',
      tip: 'financeReport.unInvoiceAmountTip',
      prefix: '¥',
      toFixd: 2
    }
  ];

  public data: FinanceReport | {} = {};

  public loadData(): void {
    const [startTime, endTime] = this.reportQuery.rangeTime;
    const query = { customerId: this.reportQuery.customerId, startTime, endTime };
    DashboardService.getFinanceReport(query)
      .then(res => {
        this.data = res;
      })
      .catch(error => {
        messageError(error);
      });
  }
}
